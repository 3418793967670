import { z } from "zod";
import { ERROR_MESSAGES } from "../constants";

export const registerUserSchema = z.object({
  name: z
    .string()
    .min(3, { message: "Ingrese un nombre con mas de 3 letras" })
    .max(250, { message: ERROR_MESSAGES.MAX_250 }),
  surname: z
    .string()
    .min(3, { message: "Ingrese un apellido con mas de 3 letras" })
    .max(250, { message: ERROR_MESSAGES.MAX_250 }),
  bornAt: z.date({ required_error: "Campo obligatorio" }),
  email: z
    .string()
    .email({ message: "Ingrese un email valido" })
    .max(250, { message: ERROR_MESSAGES.MAX_250 }),
  identityNumber: z
    .string()
    .min(7, { message: "Ingrese un dni valido" })
    .max(250, { message: ERROR_MESSAGES.MAX_250 }),
  cnr: z
    .string()
    .min(7, { message: "Ingrese un cnr valido" })
    .max(250, { message: ERROR_MESSAGES.MAX_250 }),
  is_active: z.number().optional(),

  sport: z.string().min(1, { message: "Seleccione una opción" }),
  categoryId: z.string().optional().nullable(),
  currentCategoryId: z.string().min(1, { message: "Seleccione una opción" }),
  gender: z.string().min(1, { message: "Seleccione una opción" }),
  weight: z.string().optional().nullable(),
  nationality: z.string().min(1, { message: "Seleccione una opción" }),

  phone: z.string().min(1, { message: "Escriba un numero valido" }),
  address: z.object({
    address: z.string().min(1, { message: "Escriba una dirección valida" }),
    countryCode: z.string().min(1, { message: "Seleccione una opción" }),
    autonomyCode: z.string().min(1, { message: "Seleccione una opción" }),
    provinceCode: z.string().min(1, { message: "Seleccione una opción" }),
    municipalityCode: z.string().min(1, { message: "Seleccione una opción" }),
    postalCode: z.string().min(1, { message: "Campo obligatorio" }),
  }),

  responsibles: z
    .array(
      z.object({
        familiarRelationship: z.string(),
        name: z.string(),
        surname: z.string(),
        dni: z.string(),
        phone: z.string().optional(),
        email: z.string().optional(),
      })
    )
    .optional(),

  federationId: z.string(),
  clubId: z.string(),
  belt: z.string(),
  coachType: z.string().optional(),
  refereeType: z.string().optional(),

  avatar: z.string().optional(),
  profession: z.string(),
  allergy: z.string().optional(),

  password: z.string(),
  repeatPassword: z.string(),
  //Consents
  termsAndConditionsConsent: z.string(),
  dataTreatmentConsent: z.string(),
});

export type TUserSchema = z.infer<typeof registerUserSchema>;

export const registerSchema = z.object({
  name: z.string().min(3, { message: "Ingrese un nombre con mas de 3 letras" }),
  surname: z.string(),
  bornAt: z.date({ required_error: "Campo obligatorio" }),
  email: z.string().email({ message: "Ingrese un email valido" }),
  identityNumber: z.string().min(7, { message: "Ingrese un dni valido" }),
  cnr: z.string().min(7, { message: "Ingrese un cnr valido" }),
});

export type RegisterSchema = z.infer<typeof registerSchema>;
